@charset "utf-8";

.folder_line {
    display: inline-block;
    height: 15px;
    margin-right: 3px;
    border-bottom: 1px dotted #1b67bd;
}

.level0 {
    margin-left: 8px;
}

.folder_subline {
    display: inline-block;
    height: 15px;
    margin-right: 3px;
    border-left: 1px dotted #1b67bd;
    border-bottom: 1px dotted #1b67bd;
}

.folder_list {
    border-left: 1px dotted #1b67bd;
}
.padding {
    margin-left: 29px;
}
