.mobile_approval_Btn {
    display: none;
}

.pc_approval_Btn {
    display: block;
}

.pc_approval_Btn .btn_wrap > button, .pc_approval_Btn .btn_wrap > a {
    margin-left: 10px;
}
.btn_wrap {
    margin-top:4px;
}
.approval_attach {
    width: 100%;
}

.form_control {
    border: 1px solid var(--tblr-border-color);
    border-radius: 4px;
    padding: 5px;
}

.mobile_pc_viewer {
    display: block;
    width: 800px;
    margin: 0 auto;
}

.mobile_m_viewer {
    display: none;
}

.m_file_btn {
    display: none;
}

.file_zone_footer {
    display: none;
}
.btn_option_list {
    display:none
}
.nav_toggle_btn {
        border:none;
        background:transparent;
    }

.mobile_approval p {
    font-size:17px;
}
.pd_10 {
    padding:10px;
}
@media print {
    .approval_attach {
        display: none
    }

    @page {
        size: A4;
        margin-top: 15mm;
        margin-left: 10mm;
        margin-right: 10mm;
        margin-bottom: 10mm;
    }
}

@media (max-width: 991px) {
    .file_zone_footer {
        display: none;
    }

    .document_view_card {
        width: 100%;
        overflow: hidden;
    }

    .mobile_pc_viewer {
        display: block;
        width: 100%;
        overflow-y: scroll;
    }

}

@media (max-width: 1024px) {

    .btn_option_list {
        display:block;
        width:150px;
        background:var(--tblr-body-bg);
        border:1px solid #333;
        position:fixed;
        top:68px;
        right:0;
        z-index:999;
        box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
}
    .approval_nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        width: 100%;
    }
    .mTop {
        margin-top:45px;
    }
    .btn_option_list .btn_wrap {
        width:100%
    }
    .mobile_approval_Btn .btn_wrap > div {
        display:none;
    }
    .mobile_approval_Btn .mobile_none {
        display:none;
    }

    .btn_option_list .btn_wrap > button:not(.mobile_none) {
        display:none;
    }
    .btn_option_list:before {
        position: absolute;
        top: -17px;
        right: 18px;
        width: 0;
        height: 0;
        content: "";
        display: block;
        border-bottom: 10px solid #333;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid transparent;
    }
    .btn_option_list .btn_wrap > button {
        width:100%;
        display:block;
        border:none;
        border-bottom:1px solid #ccc;
        box-shadow: none;
        border-radius: 0px;
        padding:10px;
        background:transparent;
    }
    .mobile_pc_viewer {
        display: none;
    }

    .file_zone_footer {
        display: block;
    }

    .black_bg {
        width: 100%;
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 98;
        background: rgba(0, 0, 0, 0.4);
    }

    .mobile_m_viewer {
        display: block;
        height: calc(100vh - 210px)
    }

    .btn_wrap {
        width: max-content;
        text-align: center;
        margin: 0 auto;
    }

    .file_num {
        position: absolute;
        right: -5px;
        top: -5px;
        background: red;
        color: #ffffff;
        padding: 4px 8px;
        border-radius: 10px;
        font-size: 12px;
    }

    .pc_approval_Btn {
        display: none;
    }

    .approval_attach_none {
        display: none
    }

    .approval_attach {
        position: fixed;
        width: 90%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }

    .attach_wrap {
        width: 100%;
    }

    .attach_box {
        width: fit-content;
        min-width: 100%;
    }

    .m_file_btn {
        display: block;
        position: fixed;
        bottom: 80px;
        right: 20px;
        width: 60px;
        height: 60px;
        cursor: pointer;
        border-radius: 18px;
        background: #44b1ff;
        color: #fff;
        text-align: center;
        z-index: 8;
        padding-top: 10px;
        margin-bottom: 16px;
    }

    .document_view_card {
        width: 100%;
        height: fit-content;
    }

    .mobile_approval {
        display: block;
        font-size: 17px;
        text-align: center;
    }

    .mobile_approval p {
        display: inline-block;
    }

    .mobile_approval_Btn {
        width: 100%;
        overflow-x: scroll;
    }

    .mobile_approval_Btn .btn_wrap > button, .mobile_approval_Btn .btn_wrap > a {
        width: 100px;
        height: 50px;
        margin: 4px;
    }

    .mobile_approval_Btn {
        display: block;
    }
    .mobile_back {
        padding-top:10px;
        padding-left:5px;
        padding-right:5px;
    }
}