@charset "utf-8";

.loading_spin {
  /*border: 2px solid #f3f3f3;*/
  border-radius: 50%;
  /*border-top: 2px solid #3498db;*/
  /*width: 15px;*/
  /*height: 15px;*/
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin:0 auto;
}
.loading_wrap {
  display:inline-block;
  line-height:15px;
  margin-left:5px;
  text-align: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}