@charset "utf-8";
.handover_table_wrap {
    background:#f5f5f5;
}
.handover_table {
    background: #fff;
}
.handover_table tr td {
    width:25%;
    text-align: center;
}
.handover_table tr th {
    width:25%;
    text-align: center;
    padding:7.1px;
}
.handover_table_document td {
    width:50%;
    text-align: center;
 }
.handover_table_wrap {
    height:140px;
    overflow-y: scroll;
}
.docu_list_table {
    padding-right:16px;
    border-top:2px solid #333;
    background:#f5f5f5;
}
