@charset "utf-8";
p {
    margin: 0;
    padding: 0
}

#calendar_button_today {
    color: #333;
}

.calendar_button_today {
    border: none;
    background: none;
    display: inline-block;
    cursor: pointer;
    font-size:18px;
    font-weight:600;
}


.calendar_root {
    width: 95%;
    margin:0 auto;
}

.calendar_button_today {
    width: 140px;
}
.dates {
    height:calc(100% - 42px)
}
.dates > div {
    height:16.6667%;
}
.calendar_button_prev, .calendar_button_next {
    border: none;
    background: none;
    display: inline-block;
    cursor: pointer;
    width: 50px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 13px;
    font-weight: 400;
    color: #aaa;
}

.calendar_button_nav {
    text-align: center;
}

.calendar_table_header .day {
    float: left;
    width: 14.28%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: var(--font-color);
    font-weight: 600;
}
.calendar_table {
    height:calc(100% - 60px);
    margin-bottom:10px;
}
.calendar_table_header {
    overflow: hidden;
    border: 1px solid #eee;
}

.calendar_header {
    margin-bottom: 10px;
    position: relative;
}


.weekly > div {
    width: 14.28%;
    float: left;
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.weekly > div p {
    width: 100%;
    padding:5px;
    border-bottom:1px solid #eee;
}
.weekly > div p > span {
    color:#777;
    font-size:13px;
    margin-left:4px
}
.daily_plan > p {
    border-bottom:none;
    background:#4580d7;
    color:#fff;
    width:100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 6px;
    font-size:12px;
}
.daily_plan {
    height:70px;
}
.daily_plan > div {
    padding:3px;
    text-align: center;
    margin-bottom:4px;
    color:#333;
    text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff;
    position:relative;
}
.daily_plan > div::after {
    display:block;
    content:'';
    position:absolute;
    right:-1px;
    top:0;
    width:1px;
    height:100%;
    background-color:inherit;
    z-index:9
}
.daily_plan > p:nth-child(odd) {
    background:#279863;
}

.weekly {
    border-left: 1px solid #eee;
    overflow: hidden;
}

.weekly > div > div {
    font-size: 13px;
    margin-top: 4px;
    margin-bottom:4px;
}

.deactivate p {
    opacity: 0.3;
}

.active_day {
    background: #f7f7f7;
    font-weight: 600;
}
.cal_nohands::before {
    display: inline-block !important;
    content: '';
    margin-right:4px;
    vertical-align: top;
    margin-top:6px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: #1b67bd;
}
.cal_memorial {
    color:#ff0019;
}
.date_setting_popup {
    position:absolute;
    top:50px;
    left:50%;
    transform:translateX(-50%);
    width:400px;
    background:#fff;
    z-index:10
}
.date_setting_table {
    border-collapse: collapse;
    width:100%;
}
.date_setting_table td {
    width:25%;
    height:60px;
    border:1px solid #ccc;
    text-align: center;
}
.sun {
    color:red
}
.sat {
    color:var(--sat-font-color)
}
@media (max-width: 991px) {
    .daily_plan {
    height:30px;
}
    .weekly > div p {
        text-align: center;
}

    .weekly > div > div {
    font-size: 11px;
}
    .weekly > div p span {
        display:none
}

}