
.mobile_header {
    display: none;
}


@media (min-width: 1025px) {
    .navbar_menu {
        display: none !important;
    }

    .quick_drop_menu {
        display: none !important;
    }
.admin_btn {
    display: none;
}
.nav_link_ham {
    display:none
}


}

@media (max-width: 1024px) {
    .nav_link,.nav_link_ham {
    padding-right:0!important;
    padding-left:0!important;
    }

    .admin_btn {
        display: block;
    }

    .mobile_quick_menu {
        float: left;
        width: 75px;
        height: 90px;
        text-align: center;
        background-color: var(--tblr-body-bg);
    }

    .mobile_header {
        display: block;
    }

    .focus {
        background-color: var(--focus-box);
    }

    .nav_over_box {
        height: 90px;
        float: left;
        width: 100%;
    }

    .menu_title {
        color: #21436b;
        font-size: 13px;
        text-align: center;
    }

    .mobile_quick_menu p {
        color: var(--font-color-b);
    }

    .mobile_header {
        position: fixed;
        top: 56px;
        left: 0;
        width: 100%;
        z-index: 19;
    }

    .top_header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 20
    }

    .quick_header {
        position: fixed;
        height: 91px;
        top: 56px;
        left: 0;
        width: 100%;
        z-index: 19;
        overflow-x: scroll;
        overflow-y: hidden;
        border-bottom: 1px solid var(--navbar-bd-color);
        margin-bottom: 20px;
        background-color: var(--tblr-body-bg);
        -ms-overflow-style: none; /* 인터넷 익스플로러 */
        scrollbar-width: none; /* 파이어폭스 */
    }

    .quick_header::-webkit-scrollbar {
        display: none;
    }

    .quick_drop_menu {
        position: fixed;
        z-index: 19;
        background: var(--tblr-bg-surface);
        width: 100%;
        height: 0;
        overflow: hidden;
        top: 147px;
    }

    .quick_drop_li {
        padding: 3px;
        display: block;
        border-bottom: 1px solid var(--navbar-bd-color);
    }

    .quick_drop_li img {
        width: 30px;
    }

    .quick_drop_li p {
        display: inline-block;
    }

    .quick_drop_li span {
        float: right;
        margin-top: 3px;
        margin-right: 5px;
        color: #a9b1bb;
    }

}
