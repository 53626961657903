@charset "utf-8";

/*.weekly > div > p {
    position:absolute;
    text-align: center;
    top:50%;
    transform:translateY(-50%);
    border-bottom:none;
}*/
.weekly > div {
    position: relative;
}
.cal_memorial_holiday {
    color:red;
}
.main_calendar .cal_day > div {
    height:20px;
    margin:0;
}
.main_calendar .cal_day > p {
    border-bottom:none
}
.main_calendar .main_plan_num {
    font-size:10px;
    background:#e93737;
    color:#fff;
    border-radius:15px;
    width:25px;
    height:15px;
    text-align: center;
    line-height: 10px;
    text-shadow: none;
    margin-left:4px;
}
.main_calendar .main_plan_num::after {
    display:none
}