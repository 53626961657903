@media (min-width: 1025px) {
    .mobile_list {
        display:none!important;
    }
}

@media (max-width:1024px) {
    .pc_table {
        display:none!important;
    }
}