@media print {
    .viewer_wrap {
        page-break-after: auto;
    }
    /*
    .ck-table-resized.thead {
        display: table-header-group;
    }
     */

    .table_print, .ck-table-resized {
        page-break-before: auto;
    }
    .table_content {
        page-break-after:auto;
        border-top:2px solid #333
    }

    .form_view, .table_content,.table_footer {
        width: 90%;
    }

    .approval_doc_title {
        margin-top: 0mm;
    }

    .approval_textarea_view table tr {
        page-break-after:auto;
    }
    .document_view_content {

    }
    /*table { page-break-inside: auto } tr { page-break-inside: avoid; page-break-after: auto; } thead { display: table-header-group; } tfoot { display: table-footer-group; }*/
}
.table_content {
    width: 795px;
    border-top: none
}

.approval_select {
    padding: 6px;
    border: 1px solid #ccc;
    background: var(--tblr-bg-surface)
}
.approval_form {
    border-collapse: collapse;
    border: 2px solid var(--tblr-body-color);
    width: 795px;
}
.approval_doc_title {
    text-align: center;
    font-size: 30px;
    font-weight: normal;
    /*margin-bottom: 5mm;*/
    /*margin-top: 5mm;*/
}
.approval_form td {
    padding: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
}

.form_view td, .form_view th {
    height: 39px;
}

.form_head {
    text-align: center;
    width: 90px;
}

.approval_textarea_view {
    padding: 10px;
    min-height: 580px;
    overflow: hidden;
}

.approval_textarea_view figure.table table {
    width: 100%
}
.preview_wrap .viewer_wrap h2 {
    margin-top: 20px;
}
.viewer_wrap {
    width:100%;
}

.table_footer {
    width: 795px;
}
.t_footer td {
    padding: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    height: 39px;
}

.doc_ref_list {
    cursor: pointer;
    color: #1b67bd;
    padding: 3px;
}
.approval_textarea_view td {
    border:1px solid var(--navbar-bd-color)
}
.approval_doc_title {
        text-align: center;
    }

@media (max-width: 577px) {
    .approval_doc_title {
        width:100%;
    }
}
