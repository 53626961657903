@charset "utf-8";

.approval_member {
    float: left;
    height: 90px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc
}

.approval_member:nth-of-type(5), .approval_member:nth-of-type(10) {
    border-right: none
}
.doc_state {
    width:40px;
    text-align:center
}
 .approval_member:nth-of-type(6), .approval_member:nth-of-type(7), .approval_member:nth-of-type(8), .approval_member:nth-of-type(9), .approval_member:nth-of-type(10) {
    border-bottom:none
}
.approval_member_top {
    border-bottom:none;
}
.approval_member_confirm {
    position: relative;
    width: 100%;
    height: calc(100% - 21px);
}
.approval_stamp_name {
    line-height:45px;
    display:block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width:100%;
}

.form_head {
    text-align: center;
    width: 90px;
}

.approval_confirm_img {
    height: 46px;
    text-align: center;
    position: relative;
}

.approval_confirm_img img {
    width: 40px;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.approval_member_confirm > span {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 22px;
    text-align: center;
    width: 100%;
    border-top: 1px solid #ccc;
    font-size: 12px;
    line-height: 12px;
    padding-top: 5px;
}


.approval_num {
    border: 1px solid #ccc;
    border-right:none;
    width: 20px;
    position:absolute;
    height: 21px;
    top:20px;
    right:0;
    opacity:0.4;
}

.approval_position {
    border-bottom: 1px solid #ccc;
    width: 100%;
    float: left;
    font-size: 12px;
    line-height: 20px;
    height: 21px;
    vertical-align: top;
}

.approval_date {
    text-align: center;
    font-size: 12px;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    color: #555;
    z-index: 2;
    height: 46px;
}

.approval_member {
    width: 60px;
}

.approval_member_row {
    text-align: center;
    width: 302px;
    padding: 0 !important;
}

.approval_date p:last-of-type {
    margin-top: 13px;
}