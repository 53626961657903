@charset "utf-8";

.comment_wrap {
    width:450px;
    position:fixed;
    bottom:100px;
    right:20px;
    z-index:99;
}
.card {
    background:var(--chat-bg);
    border:none;
    border-radius: 14px;
}
.chat_wrap > div {
    margin-bottom:5px;
}
.chat_wrap {
    overflow-y:scroll;
    height:255px;
}
.comment_box,.comment_box_self,.comment_box_profile  {
    overflow: hidden;
}
.comment_box_profile .comment_content_plus_wrap {
    display:block;
    width:250px;
    margin-left:56px;
    background:var(--chat-box-bg);
    border-radius: 10px;
}
.comment_box .comment_content_plus_wrap {
    display:block;
    width:250px;
    border-radius: 10px;
}
.profile_box_self .comment_content_plus_wrap {
    margin-left:0;
    margin-right:46px;
}
.comment_box_self .comment_content_plus_wrap {
    background:var(--chat-self-bg)
}
.bubble_corn:after {
    display: block;
    content:'';
    width:0;
    height:0;
    border-top:5px solid transparent;
    border-bottom:5px solid transparent;
    border-right:10px solid var(--chat-box-bg);
    position:absolute;
    top:16px;
    left:-10px;
}

.bubble_corn_me:after {
    display: block;
    content:'';
    width:0;
    height:0;
    border-top:5px solid transparent;
    border-bottom:5px solid transparent;
    border-left:10px solid var(--chat-self-bg);
    position:absolute;
    top:16px;
    right:-10px;
    z-index:11
}
.comment_box_self > div {
    float:right;
}
.comment_box_self {
    position:relative;
}
.comment_box_self .bubble_corn_me {
    transform:translateX(-10px);
}
.plus_wrap_profile {
    width:46px
}
.comment_box_self .comment_content_wrap {
    background:var(--chat-self-bg);
}
.comment_box .comment_content_plus_wrap {
    background:var(--chat-box-bg);
}
.comment_content {
    padding:0 13px 13px;
}
.comment_name {
    padding:13px 13px 5px;
    display: block;
}
.comment_box_self .comment_content_plus_wrap {
    display:block;
    float:right;
    width:250px;
    border-radius: 10px;
    position:relative;
}
.comment_content_wrap {
    display:block;
    float:left;
    width:250px;
    border-radius: 10px;
    position:relative;
    background:var(--chat-box-bg);
}
.bubble_corn_wrap {
    margin-right:10px;
}
.comment_box_profile .comment_profile {
    display:block;
    float:left;
    width:56px;
}
.comment_box_profile .comment_avatar {
    background:#f5f5f5;
    border:1px solid #ccc;
    width:40px;
    height:40px;
    border-radius: 6px;
}

.comment_box_self .comment_profile {
    display:block;
    float:right;
    width:46px;
}
.comment_box_self .comment_avatar {
    background:#f5f5f5;
    border:1px solid #ccc;
    width:40px;
    height:40px;
    border-radius: 6px;
    float:right;
}
.comment_info {
    padding:0 13px 13px;
    overflow: hidden;
}
.comment_state {
    color:#777;
    display:inline-block;
    float:left;
    font-size:13px;
}
.comment_date {
    color:#777;
    display:inline-block;
    float:right;
    font-size:13px;
}

.comment_button {
    width:60px;
    height:60px;
    cursor: pointer;
    border-radius:18px;
    background: #3D5AFE;
    color:#fff;
    text-align: center;
    position:fixed;
    bottom:20px;
    right:20px;
    z-index:8;
}
.comment_alarm {
    font-size:12px;
    border-radius:10px;
    background:red;
    color:#fff;
    text-align: center;
    padding:4px 8px;
    display:inline-block;
    position: absolute;
    right:-5px;
    top:-5px;
}

.comment_button svg {
    width:40px;
    height:40px;
    margin-top:10px;
}

.input_area_comment {
    height:50px;
}
@media (max-width: 767px) {
    .comment_wrap {
        width:320px;
    }
    .chat_wrap {
        height:155px;
    }
    .card {
    height:500px!important;
    }
    .comment_content_wrap {
        width:208px!important;
    }
    .comment_content_plus_wrap {
        width:208px!important;
    }
    .comment_date,.comment_state {
        display: block;
        float:none;
        width:100%
    }
}