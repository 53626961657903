@charset "utf-8";


.App body:after {
    content: '';
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -10;
    background: var(--tblr-body-bg);
}

* {
    font-family: 'Pretendard-Regular', sans-serif !important;
}

a {
    color: var(--a-font-color);
}

.approval_line_table {
    width: calc(50% - 5px);
    float:left;
    border-collapse: collapse;
    margin-right:5px;
}
.approval_line_table td, .approval_line_table th {
    padding: 7px;
    border: 1px solid #ccc
}
.approval_line_table th {
    font-weight: 500;
    background: #f5f5f5;
    text-align: center;
}
.approval_table {
    width: 100%;
    border-collapse: collapse;
}

.approval_table td, .approval_table th {
    padding: 7px;
    border: 1px solid #ccc
}

.approval_table th {
    font-weight: 500;
    background: #f5f5f5;
    text-align: center;
}

.table_num_ {
    width: 5%;
    text-align: center;
    border-right: 1px solid #ccc;
}

.table_w_id {
    width: 22%;
    text-align: center;
    border-right: 1px solid #ccc;
}

.table_title_head {
    width: 28%;
    border-right: 1px solid #ccc;
}

.temp_w_title_head {
    width: 75%;
    border-right: 1px solid #ccc;
}

.temp_w_title {
    width: 75%;
    border-right: 1px solid #ccc;
    cursor: pointer;
}

.table_w_title {
    width: 28%;
    border-right: 1px solid #ccc;
    cursor: pointer;
}

.temp_w_title:hover {
    background: #f5f6f9;
    text-decoration: underline;
}

.table_w_title:hover {
    background: #f5f6f9;
    text-decoration: underline;
}

.table_w_ {
    width: 10%;
    text-align: center;
    border-right: 1px solid #ccc;
}



.common_table th {
    padding: 7px;
    font-size: 14px !important;
    text-align: center;
    background-color: var(--table-top-color) !important;
    color: var(--tblr-body-color);
}

.docu_list_table .common_table th {
    border-bottom: 1px solid var(--tblr-border-color-translucent);
    color: var(--tblr-body-color);
}

.table_w {
    width: 100px
}

.table_num {
    width: 50px;
}

.common_table {
    border-collapse: collapse;
    width: 100%;
    border-radius: 7px;
    border-left: 1px solid var(--tblr-border-color-translucent);
}

.num_head {
    width: 60px;
}

.type_head {
    width: 100px
}

.company_head {
    width: 100px;
}

.name_head {
    width: 80px;
}

.input_head {
    width: 50px;
}

.input_td {
    width: 30px;
}

.common_table_head th {
    background: #f3f3f3;
    text-align: center;
}

.common_table_head th, .common_table td {
    padding: 7px;
    border-bottom: 1px solid var(--tblr-border-color-translucent);
}

.implement_table td {
    text-align: center;
}

.implement_table th {
    border-bottom: 1px solid #dadfe5
}


.padding {
    margin-left: 20px;
}

.icon-margin {
    margin-top: 3px;
}

.card_header_bg {
    background: var(--card-header-bg);
}


.container_frame::-webkit-scrollbar {
    width: 10px;
}

.container_frame::-webkit-scrollbar-thumb {
    background-color: #2f3542;
}

.container_frame::-webkit-scrollbar-track {
    background-color: grey;
}







.contents_page {
    overflow: hidden;
}

.attach_file svg.tabler-icon {
    margin: 17px auto;
}

.page_body {
    float: left;
    width: calc(100% - 250px);
    padding: 15px;
}


.preview_wrap .viewer_wrap h2 {
    margin-top: 20px;
}



.date_select {
    padding: 6px;
    border: 1px solid #ccc;
    height: 35px;
    margin-right: 3px;
    background: var(--tblr-bg-surface)
}

.docu_list_table .common_table {
    border-top: 2px solid var(--table-top-border);
}

.approval_textarea {
    padding: 6px;
    width: 100%;
    height: 500px;
    border: none;
    display: block;

    background: var(--tblr-bg-surface)
}

.approval_btn {
    border: 1px solid #ccc;
    width: 60px;
    height: 34px;
    margin-left: 5px;
    background: var(--tblr-bg-surface)
}

.approval_text {
    width: calc(100% - 80px);
    padding: 6px;
    border: 1px solid #ccc;

    background: var(--tblr-bg-surface)
}


.detail_doc_list {
    cursor: pointer;
    padding: 6px;
}

.detail_doc_list:hover {
    text-decoration: underline;
}

.aside_menu:after {
    width: 50px;
    height: 2300px;
    background: #243049;
    content: '';
    display: block;
    position: fixed;
    z-index: -8;
    top: 0
}
.member_name {
    display: inline-block;
    margin-right:10px;
}

.table_wrap {
    height: 140px;
    overflow-y: scroll;
}

.implement_table_wrap {
    height: 160px;
    min-width:300px;
}

.form_search, .form_select {
    border: 1px solid var(--tblr-border-color-translucent);;
    padding: 5px;
    border-radius: 4px;
    background: var(--tblr-btn-color);
}

.pagination {
    margin: 10px auto;
}

.doc_ref_list:hover {
    text-decoration: underline;
}




.mobile_set_btn {
    display: none;
}

.table_card {
    height:170px;
}
.pc_set_btn {
    display:block;
}
@media (max-width: 991px) {
    .container_frame {
        height: auto
    }

}


@media (max-width:1024px) {
    .mt {
        margin-top:70px;
    }
    .m_display {
        display:none
    }
    .approval_line_table {
        width:100%;
        margin-top:8px
    }
    .approval_line_table th {
        width:100px
    }

    .main_margin_top {
        margin-top: 60px;
    }

    .container_frame {
        height: auto;
    }

    .shortcut_mt {
        margin-top: 95px;
    }

    .common_margin {
        margin-top: 55px;
    }

    .navigation {
        display: none;
    }

    .page_body {
        float: none;
        width: 100%;
        padding: 15px;
        height:fit-content;
    }

    .btn_margin {
        margin-top: 10px;
    }
}

@media (max-width: 577px) {

.pc_set_btn {
    display:none;
}
    .implement_table {
        width:300px;
    }

.table_wrap {
    overflow-y:scroll;
    width:100%;
    height:140px;
}
    .table_m_over {
        overflow-y: scroll;
    }

    .mobile_set_btn {
        display: block;
    }


    .pop_action {
        display: none;
    }






    .row_margin {
        margin-top: 10px;
    }

    .over_wrap {
        width: 100%;
        overflow-x: scroll
    }
}

#body[data-bs-theme='dark'] {
    --table-top-border: #323a4f;
    --table-top-color: #31445f;
    --drop-menu-color: #182433;
    --menu-title: #202f45;
    --table-title: #202f45;
    --font-color: #fff;
    --font-color-b: #fff;
    --font-color-c: #fff;
    --chat-bg: #202f45;
    --chat-self-bg: #182433;
    --chat-box-bg: #182433;
    --navbar-bd-color: #232e47;
    --sat-font-color: #ababff;
    --focus-box: #202f45;
}

#body[data-bs-theme='light'] {
    --table-top-border: #333;
    --table-top-color: #f4f4f4;
    --drop-menu-color: #182433;
    --menu-title: #21436b;
    --table-title: #f6f6f6;
    --font-color: inherit;
    --font-color-b: #2a5a93;
    --font-color-c: #6f7c8d;
    --chat-bg: #e0edff;
    --chat-self-bg: #cdd1e7;
    --chat-box-bg: #fff;
    --navbar-bd-color: #ddd;
    --sat-font-color: blue;
    --focus-box: #d6dee9;
}

.calendar_container .container {
    padding: 0
}
