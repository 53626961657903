@charset "utf-8";


.attach_file_blur {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: rgba(255, 255, 255, 0.7);
}

.attach_file_cancel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    cursor: pointer;
    width:100%;
    text-align: center;
}
.file_name {
    display:block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
}

.attach_file:hover .attach_file_blur {
    opacity: 1;
    transition: 0.5s;
}

.attach_file:hover .attach_file_cancel {
    opacity: 1;
    transition: 0.5s;
}

@media (max-width:577px) {
    .attach_file_container {

        max-height:280px;
        overflow-y:scroll
    }
}