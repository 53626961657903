
.print_btn_basic {
    border: none;
    background: none;
    height:22px;
    padding-right:18px;
    padding-left:18px;
}
.print_list_btn {
    border-left:1px solid #ccc;
    padding-left:3px;
    padding-right:3px;
}
.dropdown {
    position:absolute;
    top:50px;
    right:0;
    width:33px;
    height:100%;
    border-top-right-radius:3px;
    border-bottom-right-radius:3px;
}
.btn_print {
    height:40px;
    margin-left:10px;
    padding:0;
}
.dropdown_content {
    position: absolute;
    background-color: #fff;
    min-width:120px;
    z-index: 1;
    top:5px;
    right:-1px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.2);
}
.dropdown_content::before {
    position:absolute;
    top:-17px;
    right:7px;
    width:0;
    height:0;
    content:"";
    display:block;
    border-bottom:10px solid #ccc;
    border-left:7px solid transparent;
    border-right:7px solid transparent;
    border-top:7px solid transparent;
}

.dropdown_content a {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
}
.dropdown_content a:not(:first-of-type) {
    border-top: 1px solid #ccc;
}

@media (max-width: 767px) {


    .btn_print {
        width: 100%;
        display: block;
        border: none;
        border-bottom: 1px solid #ccc;
        box-shadow: none;
        border-radius: 0;
        background: transparent;
        margin-left:0;
        padding:0;
        height:fit-content;
    }
    .print_btn_basic {
        padding-top:10px;
        padding-bottom:10px;
        display:block;
        border-bottom:1px solid #ccc;
        width:100%;
        height:42px;
    }
    .print_btn_basic:last-of-type {
        border-bottom:0;
    }
    .print_list_btn {
        display:inline-block;
        float:right;
        border-left:none;
        padding:10px;
        border-left:1px solid #ccc;
    }

.dropdown_content a {
    width:100%;
    padding:10px;
    text-decoration: none;
    display: block;
}

    .dropdown_content {
        border: none;
        background-color: var(--tblr-bg-surface);
        border-radius: 0;
        position: static;
        box-shadow: none;
    }
    .dropdown {
        position:static;
        width:100%;
        height:42px;
        clear: both;
    }
    .dropdown_content a {

        border-top:1px solid #ccc
    }
    .dropdown_content::before {
        display:none;
}
}