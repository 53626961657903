.day_plan {
    border:1px solid #eee;
}
.day_plan_head {
    border-bottom:1px solid #eee;
    padding:14px
}
.dayplan_input {
    border:1px solid var(--navbar-bd-color);
    padding:4px;
    display:block;
    width:100%;
    margin-bottom:10px;
    background:var(--tblr-bg-surface);
}
.day_plan_info > textarea {
    border:1px solid var(--navbar-bd-color);
    height:100px;
    resize: none;
    width:100%;
    display: block;
    padding:4px;
    background:var(--tblr-bg-surface);
}
.date_pick {
    color:var(--font-color-c);
    overflow:hidden;
}
.date_pick input {
    border:1px solid var(--navbar-bd-color);
    padding:4px;
    margin-bottom:5px;
    float:left;
    background:var(--tblr-bg-surface);
    width:calc(100% - 50px);
}

.date_pick_box p {
    width:50px;
    float:left;
    padding:5px;
}
.location {
    color:var(--font-color-c);
    overflow:hidden;
}
.boundary {
    margin-top:10px;
    color:var(--font-color-c);
    overflow:hidden;
}
.location p,.boundary p {
    color:var(--font-color-c);
    width:50px;
    float:left;
    padding:5px;
}
.boundary select {
    border:1px solid var(--navbar-bd-color);
    padding:5px;
    margin-bottom:5px;
    float:left;
    background:var(--tblr-bg-surface);
}
.location input {
    border:1px solid var(--navbar-bd-color);
    padding:4px;
    margin-bottom:5px;
    float:left;
    width:calc(100% - 50px);
    background:var(--tblr-bg-surface);
}
.color_pick_box input {
    width:30px;
    border:none;
}
.button {
    display: inline-block;
    float:right;
}
.button a {
    cursor: pointer;
    margin-top:5px;
}
.button a svg {
    color:#555
}
.button a:hover svg {
    color:#0054a6;
}
.day_plan_control {
    margin-top:5px;
}
.day_plan_control a {
    color:#555;
    cursor:pointer
}
.day_plan_control button,.day_plan_control input {
    border:1px solid var(--navbar-bd-color);
    color:#fff;
    margin-right:10px;
    padding:4px 12px;
}
.day_plan_control a:hover svg {
    color:#0054a6;
}
.day_plan_head h3 {
    display: inline-block;
    margin-right:6px;
    margin-bottom:0;
}
.btn_plan_maker {
    border:none;
    background:none;
}
.btn_edit,.btn_delete {
    background:none;
    border:none;
    color:var(--font-color);
}
.day_plan_num {
    width:6px;
    height:100%;
    background:#d7ddf5;
    text-align: center;
    vertical-align: top;
    display:inline-block;
}
.day_plan_wrap {
    height:788px;
    overflow-y: scroll;
}
.day_plan_info {
    width:calc(100% - 10px);
    height:100%;
    display: inline-block;
    padding:10px;
}
.day_plan_info > p {
    min-height:85px;
    color:var(--font-color-c);
}
.day_plan_info > h4 {
    font-size:17px;
    font-weight: 400;
    margin-bottom:5px;
}
.day_plan_wrap {
    padding:14px;
}
.day_plan_li {
    list-style: none;
    overflow: hidden;
    height:fit-content;
    margin-bottom:15px;
    background:var(--table-top-color)
}

.day_plan_ul {
    margin-bottom: 0;
    list-style: none;
    padding-left:0;
}
.day_option {
    background:var(--table-top-color);
    padding:15px;
}

.plan_box {
    display:block;
}
@media (min-width: 992px) {
   .black_box {
    display:none!important;
}
    .plan_box {
    display:block!important;
}
}
@media (max-width: 991px) {
    .black_box {
        display:block;
        background: rgba(0,0,0,0.4);
        width:100%;
        height:100%;
        position: fixed;
        z-index:1;
        top:0;
        left:0;
        color:#fff;
        padding-top:80px;
        text-align: center;
        font-size:16px;
    }
    .day_plan_wrap {
        height:400px;
        overflow-y:scroll;
    }
    .plan_box {
        position:fixed;
        bottom:0;
        left:50%;
        transform: translateX(-50%);
        background:#fff;
        z-index:10;
        display:block;
    }
}