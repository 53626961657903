@charset "utf-8";


.aside_menu {
    float: left;
    width: 50px;
    height: 100%;
    background-color: #202f45;
}

.navigation {
    width: 249px;
    overflow: hidden;
    float: left;
    height:1000px;
    background: var(--tblr-bg-surface);
    display:none;
}
.icon_list {
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    display: block;
}
.icon_list span {
    display:block;
    width:100%;
    height:100%;
    text-align: center;
    padding-top:15px;
}
.icon_list img {
    width: 25px;
    opacity: 0.8;
}

.drop_list {
    color: #fff;
    height: 50px;
    line-height: 50px;
    padding-left: 15px;
    display: block;
}

.drop_list:hover {
    background: #1b67bd;
}

.drop_nav {
    width: 0;
    background: var(--drop-menu-color);
    float: left;
    position: absolute;
    top: 56px;
    left: 50px;
    height: 100%;
    z-index:1;
    overflow-x:clip;
}
.drop_nav:after {
    width:100%;
    height:1300px;
    background: var(--drop-menu-color);
    content:'';
    position:absolute;
    top:0;
    left:0;
    z-index:-1;
}

.drop_nav a:hover {
    background: #202f45;
    text-decoration: none;
}

.drop_nav a {
    color: #fff;
    width: 100%;
    height: 50px;
    line-height: 50px;
    display: block;
    padding-left: 10px;
    cursor: pointer;
}

.nav_detail {
    float: left;
    background: var(--tblr-bg-surface);
    width: 199px;
    height: 100%;
}

.nav_title {
    background: #4580d7;
    color: #fff;
}

.nav_title h2 {
    font-weight: 400;
    background: var(--menu-title);
    padding: 15px 10px 15px 21px;
    margin-bottom: 0;
}

.nav_title p {
    padding: 10px;
}


.menu_category {
    padding: 15px 10px;
    border-top: 1px solid var(--tblr-border-color-translucent);
    display: block;
}

.menu_list div > p, .menu_category p {
    margin-bottom: 0;
    display: inline-block;
    font-size: 17px;
    vertical-align: top;
}

.menu_list > div:last-of-type {
    border-bottom: 1px solid var(--tblr-border-color-translucent);
}

.menu_list div > svg {
    vertical-align: top;
    margin-top: 3px;
}

.detail_menu {
    padding: 10px 0 10px 21px;
    border-top: 1px solid var(--tblr-border-color-translucent);
}

.detail_menu a {
    display: block;
    margin-bottom: 4px;
    margin-top: 4px;
}
.nav_tab {
    height:100%;
}
@media (min-width:1025px) {
    .navigation {
        display:block;
    }
    #navbar-menu {
        display: none !important;
    }

}
